<template>
  <section class="dtc-main-section mt-4" id="">
    <header
      class="dtc-header"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      <div>
        {{ school }}{{ year }}學年度第{{
          term
        }}學期「原住民及離島地區醫事人員養成計畫」
      </div>
      <div>教學設備採購品目表(申請用)</div>
    </header>
    <header class="h2">可用預算額度:{{ chineseAmount }}</header>
    <header class="grid-7">
      <div v-for="item in names" :key="item">{{ item }}</div>
    </header>
    <main class="grid-7" v-for="(item, i) in devices" :key="i">
      <div>{{ i + 1 }}</div>
      <div>{{ item.DeviceName }}</div>
      <div>{{ item.Quantity }}</div>
      <div style="text-align:right;padding-right:5px">
        {{ $formatPrice(item.PriceFee) }}
      </div>
      <div style="text-align:right;padding-right:5px">
        {{ $formatPrice(item.SubTotal) }}
      </div>
      <div>{{ item.Remark }}</div>
      <div>{{ item.Purpose }}</div>
    </main>
    <main class="grid-6">
      <div></div>
      <div></div>
      <div style="text-align:left" class="pl-1">衛生福利部補助款金額</div>
      <div style="text-align:right;padding-right:5px">
        {{ $formatPrice(map.Device_Amount) }}
      </div>
      <div></div>
      <div></div>
    </main>
    <main class="grid-6">
      <div></div>
      <div></div>
      <div style="text-align:left" class="pl-1">學校自籌款金額</div>
      <div style="text-align:right;padding-right:5px">
        {{ $formatPrice(raiseAmount) }}
      </div>
      <div></div>
      <div></div>
    </main>
    <main class="grid-6">
      <div></div>
      <div></div>
      <div style="text-align:left" class="pl-1">合計</div>
      <div style="text-align:right;padding-right:5px">
        {{ $formatPrice(amount) }}
      </div>
      <div></div>
      <div></div>
    </main>
    <footer style="border:0px">
      <div>【備註】</div>
      <div>
        1.醫學系及牙醫學系公費生每名每年新台幣3萬元，其他學系公費生每名每年新台幣1萬5,000元。
      </div>
      <div>
        2.本案補助設備定義為金額一萬元以上且使用年限，在兩年以上，依規定應編製財產增加單。
      </div>
      <div>
        3.設備採購金額或品項倘有變更，應於每年11月底前函報本部進行變更，並以一次為原則。
      </div>
      <div>
        4.申請金額係為本學期因執行本計畫所申請之教學設備補助經費，非使用本計畫經費者，無須採計。
      </div>
      <div>
        5.倘有申請金額與總補助額度有差異或是有不同經費來源(如經費分攤等)，致經費有所差異，請於備註欄位處說明，以利核對。
      </div>
    </footer>
  </section>
</template>

<script>
const names = [
  "編號",
  "設備名稱",
  "數量",
  "單價",
  "合計(總價)",
  "經費來源",
  "用途說明",
];
import queryString from "query-string";

export default {
  name: "totalSpend15",
  data() {
    return {
      year: "",
      names,
      term: 1,
      school: "",
      budege: "138,373",
      raiseAmount: 0,
      devices: [],
      amount: 0,
      map: {},
      chineseAmount: "",
    };
  },
  methods: {
    async getData() {
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "109",
        term = 1,
        category: Category = "S",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = term; // == 1 ? '上' : '下';
      this.year = Byear;
      let schoolArr = await window.axios.get("School/GetAll");
      this.school = schoolArr.find(
        (s) => s.SchoolCode == SchoolCode
      ).SchoolName;
      const url = `Budgeting/GetDeviceList?byear=${Byear}&term=${term}&schoolcode=${SchoolCode}&category=${Category}`;
      try {
        const map = await window.axios.get(url);
        if (map) {
          this.map = map;
          this.devices = map.equipment_detail;
          this.budege = map.TotalAmount;
          this.chineseAmount = Boolean(map.Chinese_Amount.split("元")[0])
            ? map.Chinese_Amount
            : "零元整";
          this.amount = this.devices.reduce(
            (acc, { SubTotal }) => (acc += +SubTotal),
            0
          );
          this.raiseAmount =
            this.amount - map.Device_Amount > 0
              ? this.amount - map.Device_Amount
              : 0;
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
  },
  async mounted() {
    const { schoolcode, byear, term, category } = queryString.parse(
      location.href.split("?")[1]
    );
    this.term = +term;
    this.bYear = +byear;

    //for check is same school-prevent see other school
    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolcode) {
        return;
      }
    }

    //check Can see and then get data
    this.getData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  width: 1280px;
  margin: 0 auto;
}
.dtc-header {
  border-bottom: 1px solid black;
  height: 80px;
  font-size: 24px;
  text-align: center;
}
.h2 {
  border: 1px solid black;
  border-top: none;
  height: 40px;
  font-size: 18px;
  text-align: left;
  line-height: 40px;
  padding-left: 5px;
  font-weight: bold;
  margin-bottom: 0;
}
.grid-7,
.grid-6 {
  display: grid;
  border: 1px solid black;
  // height: 30px;
  border-top: none;
  grid-template-columns: 50px 210px 50px 120px 120px 210px 1fr;
  > div {
    border-right: 1px solid black;
    // height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
  }
  > div:last-child {
    border-right: none;
  }
}
.grid-6 {
  grid-template-columns: 50px 210px 170px 120px 210px 1fr;
}

footer {
  border: 1px solid black;
  border-top: none;
  height: 140px;
  font-size: 13px;
  text-align: left;
  padding-left: 2px;
  padding-top: 10px;
}
@media print {
  @page {
    size: A4 landscape;
  }
  .dtc-main-section {
    max-width: 100vw;
  }
  .dtc-print {
    width: 1280px;
  }
}
</style>
